var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"z3B5HQfkpvoG_1FpM336v"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { Environment } from './helpers';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Replay may only be enabled for the client-side
  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate:
    process.env.NEXT_PUBLIC_WEBSITE_ENV === Environment.PRODUCTION ? 0.2 : 1.0,

  environment: process.env.NEXT_PUBLIC_WEBSITE_ENV,
  debug:
    process.env.NEXT_PUBLIC_WEBSITE_ENV === Environment.PRODUCTION
      ? false
      : true,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    /w\.adroll\.push is not a function/i,
    /TypeError: w\.adroll\.push is not a function/i,
    /w\.adroll\.push is not a function\. \(In 'w\.adroll\.push\(\[n, arguments\]\)', 'w\.adroll\.push' is undefined\)/i,
    /TypeError: w\.adroll\.push is not a function\. \(In 'w\.adroll\.push\(\[n, arguments\]\)', 'w\.adroll\.push' is undefined\)/i,
    /Failed to fetch dynamically imported module: https:\/\/www\.googletagmanager\.com\/gtag\/js\?id=G-1X0XQRMB4F/i,
    /TypeError: Failed to fetch dynamically imported module: https:\/\/www\.googletagmanager\.com\/gtag\/js\?id=G-1X0XQRMB4F/i,
  ],
  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
